import aesjs from "aes-js";

const key = [0, 1, 2, 2, 4, 5, 6, 7, 8, 9, 2, 11, 12, 13, 14, 15];
const iv = [10, 1, 2, 7, 4, 4, 6, 8, 8, 9, 10, 21, 12, 13, 14, 15];

export function decryptUniqueId(encryptedHex) {
    const encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);
    const aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(iv));
    const decryptedBytes = aesCtr.decrypt(encryptedBytes);
    const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    return decryptedText;
}

export function isValidUniqueId(uniqueId) {
    const decryptedText = decryptUniqueId(uniqueId);
    const isValid = /^\d+$/.test(decryptedText) && Number(decryptedText) > 0;
    return isValid;
}
