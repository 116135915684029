import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home.js";
import Scanpage from "./Scanpage.js";
import Mainpage from "./MainPage.js";
import Borne from "./Borne.js";
import Errordata from "./ErrorData.js";
import TypeDeDechet from "./module/TypeDeDechet.js";
import DemandeDeService from "./module/DemandeDeService.js";
import QueDevientMonDechet from "./module/queDevientMonDechet.js";
import Wiew from "./module/Wiew.js";
import WiewState from "./module/WiewState.js";
import Contact from "./module/Contact.js";
import Sondage from "./module/Sondage.js";
import DemoContact from "./DemoContact.js";
import Demo from "./Demo.js";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Admin from "./Admin.js";
import RedirectCA from "./RedirectCA.js";
import RedirectVincifm from "./RedirectVincifm.js";
import LanguageValueProvider from "../context/LanguageValue.js";

function Page({ children }) {
    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    );
}

export default function Body() {
    return (
        <LanguageValueProvider>
            <Routes>
                <Route path="/scanpage/*" element={<Scanpage />} />
                <Route path="/mainpage" element={<Mainpage />} />
                <Route path="/errordata" element={<Errordata />} />
                <Route
                    path="/borne"
                    element={
                        <Page>
                            <Borne />
                        </Page>
                    }
                />
                <Route
                    path="/typeDeDechet"
                    element={
                        <Page>
                            <TypeDeDechet />
                        </Page>
                    }
                />
                <Route
                    path="/demandeDeService"
                    element={
                        <Page>
                            <DemandeDeService />
                        </Page>
                    }
                />
                <Route
                    path="/queDevientMonDechet"
                    element={
                        <Page>
                            <QueDevientMonDechet />
                        </Page>
                    }
                />
                <Route
                    path="/wiew"
                    element={
                        <Page>
                            <Wiew />
                        </Page>
                    }
                />
                <Route
                    path="/wiewstate"
                    element={
                        <Page>
                            <WiewState />
                        </Page>
                    }
                />
                <Route
                    path="/contact"
                    element={
                        <Page>
                            <Contact />
                        </Page>
                    }
                />
                <Route
                    path="/sondage"
                    element={
                        <Page>
                            <Sondage />
                        </Page>
                    }
                />
                <Route path="/democontact" element={<DemoContact />} />
                <Route path="/magazine" element={<Demo />} />
                <Route path="/qr/*" element={<Demo />} />
                <Route path="/admins" element={<Admin />} />
                <Route
                    path="/scanpage/cacentreest/france/champagne/pierre/01"
                    element={<RedirectCA />}
                />
                <Route path="/scanpage/FACEO" element={<RedirectVincifm />} />
                <Route path="/*" element={<Home />} />
            </Routes>
        </LanguageValueProvider>
    );
}
