import React, { useContext, useEffect, useState } from "react";
import { RedirectUrlValue } from "../context/RedirectUrlValue.js";
import { isValidUniqueId } from "./Aes.js";

export default function Demo() {
    const [isScan, setIsScan] = useState(false);
    const { toggleredirectUrl } = useContext(RedirectUrlValue);
    const scanData = `https://${document.location.host}/scanpage/greenoffice/france/alencon/albert/5/?auth=bcea01b1fe41`;

    useEffect(() => {
        if (scanData) {
            const qrResult = scanData.toLowerCase();
            const urlSplit = qrResult.split("/");
            const isValid = isValidUniqueId(
                urlSplit[urlSplit.length - 1].split("=")[1]
            );
            const urlArray = urlSplit.slice(urlSplit.length - 6).slice(0, 5);
            toggleredirectUrl(
                urlArray,
                true,
                urlArray[0],
                urlArray[1],
                urlArray[2],
                urlArray[3],
                urlArray[4]
            );
            if (isValid) {
                setIsScan(true);
            }
        }
    }, [scanData, toggleredirectUrl]);

    useEffect(() => {
        if (isScan === true) {
            window.location.replace("/mainpage");
        }
    }, [isScan]);

    return <p></p>;
}
