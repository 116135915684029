import React, { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Gmclassic } from "../assets/svg/logo/GmClassic.svg";
import { LanguageValue } from "../context/LanguageValue";
import FrenchFlag from "../assets/svg/header/Drapeau01.svg";
import DeutchFlag from "../assets/svg/header/Drapeau03.svg";
import EnFlag from "../assets/svg/header/Drapeau02.svg";
import "../styles/Home.css";
import { getOnLanguage } from "./api";
import Loading from "./Loading";

export default function Home() {
    const { toggleLanguage, language } = useContext(LanguageValue);
    const navigate = useNavigate();
    const [onLanguage, setOnLanguage] = useState("");

    function handleNavigate() {
        let code = "FR";
        if (language === "Français") {
            code = "FR";
        } else if (language === "deutsch") {
            code = "DE";
        } else {
            code = "EN";
        }
        navigate(
            `/scanpage/GreenOffice${code}/${language}/alencon/albert/01/?auth=b8e007b5fd41`
        );
    }

    const fetchData = useCallback(async () => {
        try {
            const languageData = await getOnLanguage(language);
            setOnLanguage(languageData);
        } catch (error) {
            console.log(
                "Une erreur s'est produite lors de la récupération des données."
            );
        }
    }, [language]);

    useEffect(() => {
        fetchData();
    }, [language, fetchData]);

    useEffect(() => {
        console.log("langue du navigateur : " + navigator.language);
        if (navigator.language.includes("fr")) {
            toggleLanguage("Français");
        } else if (navigator.language.includes("en")) {
            toggleLanguage("anglais");
        } else if (navigator.language.includes("de")) {
            toggleLanguage("deutsch");
        } else {
            toggleLanguage("anglais");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="homePage">
            <Gmclassic
                className="Gmclassic"
                fill="#1B9A68"
                alt="Fléche retour"
            />
            <div className="content">
                {onLanguage ? (
                    <>
                        {onLanguage && onLanguage.home && (
                            <>
                                <p>{onLanguage.home.scan}</p>
                                <p>{onLanguage.home.ou}</p>
                                {/* <div onClick={handleScan} className="activeButton">{data[language].home.buttonDemo}</div> */}
                                <div
                                    onClick={() => handleNavigate()}
                                    className="activeButton"
                                >
                                    {onLanguage.home.buttonDemo}
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <Loading />
                )}
            </div>
            <div className="flags">
                <img
                    src={FrenchFlag}
                    onClick={() => toggleLanguage("Français")}
                    alt=""
                />
                <img
                    src={DeutchFlag}
                    onClick={() => toggleLanguage("deutsch")}
                    alt=""
                />
                <img
                    src={EnFlag}
                    onClick={() => toggleLanguage("anglais")}
                    alt=""
                />
            </div>
        </div>
    );
}
