import Body from "./Body.js";
import RedirectUrlValueProvider from "../context/RedirectUrlValue.js";
import "../styles/App.css";

export default function App() {
    return (
        <RedirectUrlValueProvider>
            <div className="App">
                <Body />
            </div>
        </RedirectUrlValueProvider>
    );
}
