import React, { useEffect, useState, useContext, useCallback } from "react";
import "../styles/Footer.css";
import { ReactComponent as Footernav } from "../assets/svg/footer/FooterNav.svg";
import { useNavigate } from "react-router-dom";
import { RedirectUrlValue } from "../context/RedirectUrlValue.js";
import PopupMerci from "./popup/Merci.js";
import { getClientByClient } from "./api";

export default function Footer() {
    const navigate = useNavigate();
    const [showPopupMerci, setshowPopupMerci] = useState(false);
    const [error, setError] = useState(null);
    const { brand } = useContext(RedirectUrlValue);
    const [onData, setOnData] = useState("");

    const fetchData = useCallback(async () => {
        try {
            const clientData = await getClientByClient(brand);
            setOnData(clientData);
        } catch (error) {
            setError(
                "Une erreur s'est produite lors de la récupération des données."
            );
        }
    }, [brand]);

    if (error) {
        navigate("/");
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    function handleNoPopup() {
        setshowPopupMerci(false);
    }

    useEffect(() => {
        if (showPopupMerci === true) {
            setTimeout(() => {
                document.location.reload();
            }, 2000);
        }
    }, [showPopupMerci]);

    return (
        <footer id="footer">
            {showPopupMerci ? (
                <div className="popups">
                    <div
                        onClick={() => handleNoPopup()}
                        className="shadow"
                    ></div>
                    <div className="radiusBorder">
                        <PopupMerci setshowPopupMerci={setshowPopupMerci} />
                    </div>
                </div>
            ) : (
                <React.Fragment></React.Fragment>
            )}

            <Footernav
                fill={onData.couleur}
                onClick={() => navigate("/mainpage")}
                className="footerBackground"
                alt="footer"
            />
            <div id="puces"></div>
        </footer>
    );
}
