import React, { useEffect, useState, useContext } from "react";
import { RedirectUrlValue } from "../../context/RedirectUrlValue.js";
import { LanguageValue } from "../../context/LanguageValue";
import { useNavigate } from "react-router-dom";
import { getClientByClient, getOnLanguage } from "../api";
import Loading from "../Loading";

export default function DesactiveModule() {
    const [onLanguage, setOnLanguage] = useState("");
    const { brand } = useContext(RedirectUrlValue);
    const { language } = useContext(LanguageValue);
    const navigate = useNavigate();
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const clientData = await getClientByClient(brand);
                if (clientData.version === "demo") {
                    const languageData = await getOnLanguage(language);
                    setOnLanguage(languageData);
                } else {
                    const languageData = await getOnLanguage(language);
                    setOnLanguage(languageData);
                }
            } catch (error) {
                setError(error);
            }
        };
        fetchData();
    }, [brand, language]);

    useEffect(() => {
        if (error) {
            navigate("/");
        }
    }, [error, navigate]);

    return (
        <div className="popup popupComment">
            {onLanguage ? (
                <h3>{onLanguage.popupDesactiveModule.text}</h3>
            ) : (
                <Loading />
            )}
        </div>
    );
}
