import React, { useState, useContext, useEffect } from "react";
import "../styles/LogoAnnimation.css";
import { RedirectUrlValue } from "../context/RedirectUrlValue.js";
import { useNavigate } from "react-router-dom";
import { getClientByClient } from "./api";

export default function Mail() {
    const { brand } = useContext(RedirectUrlValue);
    const [onData, setOnData] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const clientData = await getClientByClient(brand);
                setOnData(clientData);
            } catch (error) {
                setError(error);
            }
        };
        fetchData();
    }, [brand]);

    useEffect(() => {
        if (error) {
            console.log(error);
            navigate("/");
        }
    }, [error, navigate]);

    return (
        <React.Fragment>
            <style>
                {`
                .SVGLogo{
                    animation: SVGLogo 20s ease forwards;
                }

                @keyframes SVGLogo {
                    0% {
                        transform: scale(0);
                        opacity: 0;
                    }
                    5% {
                        opacity: calc(${onData.gmLogo}/2);
                    }
                    7% {
                        transform: scale(1);
                        opacity: ${onData.gmLogo};
                    }
                    10% {
                        transform: scale(1);
                        opacity: ${onData.gmLogo};
                    }
                    15%{
                        opacity: ${onData.gmLogo};
                    }
                    100% {
                        opacity: ${onData.gmLogo};
                    }
                }
                `}
            </style>

            <svg
                className="SVGLogo"
                fill={onData.couleur}
                width="173"
                height="265"
                viewBox="0 0 173 265"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    alt="cercle"
                    className="O-stroke"
                    cx="86.4474"
                    cy="87.8752"
                    r="41.5"
                    stroke="black"
                    strokeWidth="21"
                    fill="none"
                />
                <line
                    alt="dot"
                    x1="152"
                    y1="20.5"
                    x2="152"
                    y2="20.5"
                    stroke={onData.couleur}
                    className="red-dot"
                />
                <path
                    className="LogoText"
                    alt="2eme E"
                    d="M108.595 240.986H87.1871C88.4489 243.775 91.2771 245.561 94.3229 245.518H97.1076V251.836H94.3229C86.4909 251.836 80.0513 245.561 80.0513 237.806C80.0513 230.05 86.4909 223.776 94.3229 223.776C102.111 223.776 108.595 230.05 108.595 237.806V240.986ZM101.546 234.625C100.719 232.926 99.4137 231.575 97.7168 230.791C93.7572 228.961 89.0581 230.616 87.2306 234.625H101.546Z"
                    fill="black"
                />
                <path
                    className="LogoText"
                    alt="1er E"
                    d="M77.9627 240.986H56.5117C57.8171 243.775 60.6018 245.561 63.6911 245.518H66.4758V251.836H63.6911C55.8156 251.836 49.4194 245.561 49.4194 237.806C49.4194 230.05 55.7721 223.776 63.6911 223.776C71.436 223.776 77.9627 230.05 77.9627 237.806V240.986ZM70.8269 234.625C70.0437 232.926 68.6948 231.575 66.9979 230.791C63.0384 228.961 58.3392 230.616 56.5117 234.625H70.8269Z"
                    fill="black"
                />
                <path
                    className="LogoText"
                    alt="3éme E"
                    d="M172.295 240.986H150.887C152.193 243.775 155.021 245.561 158.067 245.518H160.851V251.836H158.067C150.191 251.836 143.795 245.561 143.795 237.806C143.795 230.05 150.148 223.732 158.067 223.732C165.812 223.732 172.338 230.05 172.338 237.806L172.295 240.986ZM165.202 234.625C163.375 230.66 158.676 228.961 154.716 230.791C153.019 231.575 151.714 232.926 150.887 234.625H165.202Z"
                    fill="content"
                />
                <path
                    className="LogoText"
                    alt="Début M"
                    d="M126.173 225.824C121.996 222.512 115.948 223.253 112.641 227.436C111.336 229.179 110.596 231.27 110.596 233.405V251.749H118.036V233.405C118.08 232.185 119.168 231.27 120.342 231.314C121.517 231.357 122.431 232.272 122.474 233.405C122.474 230.442 123.867 227.654 126.173 225.824Z"
                    fill="black"
                />
                <path
                    className="LogoText"
                    alt="Fin M"
                    d="M126.173 225.824C130.393 222.512 136.441 223.253 139.792 227.436C141.097 229.179 141.837 231.27 141.837 233.405V251.749H134.353V233.405C134.353 232.185 133.396 231.226 132.177 231.226C130.959 231.226 130.002 232.185 130.002 233.405C129.958 230.442 128.566 227.654 126.173 225.824Z"
                    fill="content"
                    fillOpacity="0.68"
                />
                <path
                    className="LogoText"
                    alt="Milieux M"
                    d="M129.959 233.405C129.959 230.442 128.566 227.61 126.173 225.823C123.823 227.61 122.475 230.486 122.475 233.405V251.749H129.959V233.405Z"
                    fill="content"
                    fillOpacity="0.92"
                />
                <path
                    className="LogoText"
                    alt="G"
                    d="M20.7022 223.776V225.649C18.8312 224.734 16.7427 224.168 14.4801 224.168C6.82213 224.168 0.643555 230.399 0.643555 238.111C0.643555 245.779 6.86564 251.967 14.4801 251.967C16.7427 251.967 18.8312 251.444 20.7022 250.485V252.272C20.5281 253.056 19.8754 255.757 17.0907 256.672C16.5686 256.847 16.09 256.89 15.5243 256.934L15.6984 264.646C16.9602 264.602 18.2655 264.384 19.3968 264.036C25.8365 261.988 28.0555 256.149 28.4036 252.969V252.751V223.776H20.7022ZM14.7411 245.387C10.5641 245.387 7.21373 242.032 7.21373 237.849C7.21373 233.666 10.5641 230.311 14.7411 230.311C18.9182 230.311 22.2686 233.666 22.2686 237.849C22.2686 242.032 18.9182 245.387 14.7411 245.387Z"
                    fill="black"
                />
                <path
                    className="LogoText"
                    alt="R"
                    d="M49.7677 225.911L48.0708 224.691C45.5907 222.904 41.7182 223.514 39.4991 225.649L39.0205 226.128V223.819H31.7542V251.966H39.0205V239.2C39.0205 237.109 39.7167 235.061 41.0655 233.449L41.9793 232.359C43.2846 230.878 45.1991 231.27 45.8953 231.793C46.1128 231.967 46.4609 231.88 46.5914 231.619L49.8112 226.564C50.0723 226.433 50.0288 226.085 49.7677 225.911Z"
                    fill="black"
                />
                <circle
                    alt="cercle"
                    className="O-2-stroke"
                    cx="86.4474"
                    cy="142"
                    r="41.5"
                    stroke="black"
                    strokeWidth="21"
                    fill="none"
                />
            </svg>
        </React.Fragment>
    );
}
