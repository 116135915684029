import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getClientByClient, getModuleById } from "../api";
import { RedirectUrlValue } from "../../context/RedirectUrlValue.js";
import "../../styles/Wiew.css";

export default function Wiew() {
    const { state } = useLocation();
    const [error, setError] = useState(null);
    const [onData, setOnData] = useState("");
    const [module, setModule] = useState({});
    const [total, setTotal] = useState(0);
    const [totalVide, setTotalVide] = useState(0);
    const { brand } = useContext(RedirectUrlValue);
    const [activeFilter, setActiveFilter] = useState("button1");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const clientData = await getClientByClient(brand);
                setOnData(clientData);
                const moduleData = await getModuleById(state.module);
                setModule(moduleData);
            } catch (error) {
                setError(
                    "Une erreur s'est produite lors de la récupération des données."
                );
            }
        };
        fetchData();
    }, [brand, state.module]);

    useEffect(() => {
        if (error) {
            navigate("/");
        }
    }, [error, navigate]);

    useEffect(() => {
        let tempototal = 0;
        let tempototalVide = 0;

        if (module.details) {
            for (let i = 0; i < module.bornePage.length; i++) {
                tempototalVide =
                    tempototalVide + parseFloat(module.bornePage[i].bornevide);
                tempototal =
                    tempototal + parseFloat(module.bornePage[i].bornetotal);
            }
            setTotal(tempototal);
            setTotalVide(tempototalVide);
        }
    }, [module]);

    setTimeout(() => {
        const graphCircle = document.querySelector(".graph-circle");
        if (graphCircle) {
            if (Math.trunc((totalVide / total) * 100) < 50) {
                graphCircle.classList.add("circle-animation-false");
            } else {
                graphCircle.classList.add("circle-animation-true");
            }
        }
    }, 100);

    let dateObj = new Date();
    let day = dateObj.getDate();
    let month = dateObj.getMonth();

    function handleRedirect(borne) {
        const stateData = {
            borne: borne,
            imageUrl: module.imageUrl,
        };
        navigate("/wiewstate", { state: stateData });
    }

    return (
        <>
            {module && module.details ? (
                <div className="Wiew page">
                    <div className="titlePage">
                        <img
                            src={module.imageUrl}
                            alt="représentation du module"
                        />
                        <div className="centerTexte">
                            <p>ETAT DES BORNES</p>
                        </div>
                    </div>
                    <div className="buttonFilter">
                        <button
                            id="button1"
                            onClick={() => setActiveFilter("button1")}
                            style={{
                                background:
                                    activeFilter === "button1"
                                        ? onData.couleur
                                        : "grey",
                            }}
                            className="activeButton twoButton"
                        >
                            Déchets
                        </button>
                        <button
                            id="button2"
                            onClick={() => setActiveFilter("button2")}
                            style={{
                                background:
                                    activeFilter === "button2"
                                        ? onData.couleur
                                        : "grey",
                            }}
                            className="activeButton twoButton"
                        >
                            Étages
                        </button>
                    </div>
                    <div className="TotalData">
                        <div className="animation-graph-container mb-3">
                            <svg viewBox="-2 -2 40 25">
                                <path
                                    className="circle-bg"
                                    strokeDasharray={`50, 100`}
                                    d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                                <path
                                    className="graph-circle"
                                    strokeDasharray={`${
                                        Math.trunc((totalVide / total) * 100) /
                                        2
                                    }, 100`}
                                    d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                                {Math.trunc((totalVide / total) * 100) ===
                                100 ? (
                                    <text x="7" y="17.5" className="graph-text">
                                        {Math.trunc((totalVide / total) * 100)}%
                                    </text>
                                ) : (
                                    <text x="9" y="17.5" className="graph-text">
                                        {Math.trunc((totalVide / total) * 100)}%
                                    </text>
                                )}
                            </svg>
                        </div>
                        <div className="filter">
                            <p>
                                {totalVide}/{total} BORNES VIDÉES
                            </p>
                            <p>
                                {day - 1}/{month} 6H47 - {day - 1}/{month} 16H50
                            </p>
                        </div>
                    </div>
                    {activeFilter === "button1"
                        ? module.bornePage.map((borne, index) => (
                              <div
                                  onClick={() => handleRedirect(borne)}
                                  key={index}
                                  className="card"
                              >
                                  <img
                                      src={borne.image}
                                      alt="représentation de la borne"
                                  />
                                  <p className="textcenter">{borne.text}</p>
                                  <div className="cardstat">
                                      {(borne.bornevide / borne.bornetotal) *
                                          100 >
                                      50 ? (
                                          <>
                                              <p style={{ color: "#6BC44C" }}>
                                                  {Math.trunc(
                                                      (borne.bornevide /
                                                          borne.bornetotal) *
                                                          100
                                                  )}
                                                  %
                                              </p>
                                              <p style={{ color: "#6BC44C" }}>
                                                  {borne.bornevide}/
                                                  {borne.bornetotal}
                                              </p>
                                          </>
                                      ) : (
                                          <>
                                              <p style={{ color: "#FFA857" }}>
                                                  {Math.trunc(
                                                      (borne.bornevide /
                                                          borne.bornetotal) *
                                                          100
                                                  )}
                                                  %
                                              </p>
                                              <p style={{ color: "#FFA857" }}>
                                                  {borne.bornevide}/
                                                  {borne.bornetotal}
                                              </p>
                                          </>
                                      )}
                                  </div>
                                  <p className="nextpage">{">>"}</p>
                              </div>
                          ))
                        : module.etagePage.map((borne, index) => (
                              <div
                                  onClick={() => handleRedirect(borne)}
                                  key={index}
                                  className="card"
                              >
                                  <img
                                      src={module.imageUrl}
                                      alt="représentation de la borne"
                                  />
                                  <p className="textcenter">{borne.text}</p>
                                  <div className="cardstat">
                                      {(borne.bornevide / borne.bornetotal) *
                                          100 >
                                      50 ? (
                                          <>
                                              <p style={{ color: "#6BC44C" }}>
                                                  {Math.trunc(
                                                      (borne.bornevide /
                                                          borne.bornetotal) *
                                                          100
                                                  )}
                                                  %
                                              </p>
                                              <p style={{ color: "#6BC44C" }}>
                                                  {borne.bornevide}/
                                                  {borne.bornetotal}
                                              </p>
                                          </>
                                      ) : (
                                          <>
                                              <p style={{ color: "#FFA857" }}>
                                                  {Math.trunc(
                                                      (borne.bornevide /
                                                          borne.bornetotal) *
                                                          100
                                                  )}
                                                  %
                                              </p>
                                              <p style={{ color: "#FFA857" }}>
                                                  {borne.bornevide}/
                                                  {borne.bornetotal}
                                              </p>
                                          </>
                                      )}
                                  </div>
                                  <p className="nextpage">{">>"}</p>
                              </div>
                          ))}
                </div>
            ) : (
                <p>Loading Data ....</p>
            )}
        </>
    );
}
