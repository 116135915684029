import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getModuleById } from "../api";
import { RedirectUrlValue } from "../../context/RedirectUrlValue.js";
import "../../styles/Contact.css";
import { ReactComponent as StartSelected } from "../../assets/svg/StarGreen.svg";
import { ReactComponent as StartUnSelected } from "../../assets/svg/StarBlack.svg";
import Loading from "../Loading.js";
import { LanguageValue } from "../../context/LanguageValue";
import { getCompleteClientInfo } from "../api";
import { createDataSondage } from "../api";

export default function Sondage() {
    const { state } = useLocation();
    const [error, setError] = useState(null);
    const [module, setModule] = useState({});
    const { brand, color } = useContext(RedirectUrlValue);
    const navigate = useNavigate();
    const [dataSondage, setDataSondage] = useState([]);
    const [valueSondage, setValueSondage] = useState(0);
    const [colorStar, setColorStar] = useState(0);
    const [result, setResult] = useState([]);
    const [animationClass, setAnimationClass] = useState("");
    const [isComplet, setIsComplet] = useState(false);
    const { language } = useContext(LanguageValue);
    const [onLanguage, setOnLanguage] = useState("");
    const [onData, setOnData] = useState("");
    const [isStart, setIsStart] = useState(false);
    const [formData, setFormData] = useState({
        nom: "",
        email: "",
        entreprise: "",
    });

    const fetchData = useCallback(async () => {
        try {
            const { clientData, languageData } = await getCompleteClientInfo(
                brand,
                language
            );
            setOnData(clientData);
            setOnLanguage(languageData);
        } catch (error) {
            setError(
                "Une erreur s'est produite lors de la récupération des données."
            );
        }
    }, [brand, language]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (error) {
        navigate("/");
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const moduleData = await getModuleById(state.module);
                setModule(moduleData);
                setDataSondage(moduleData.dataQuestion);
            } catch (error) {
                setError(
                    "Une erreur s'est produite lors de la récupération des données."
                );
            }
        };
        fetchData();
    }, [brand, state.module]);

    useEffect(() => {
        if (error) {
            console.log(error);
            navigate("/");
        }
    }, [error, navigate]);

    function HandleSelect(param) {
        setColorStar(param);
        const updatedResult = [...result, param];
        setResult(updatedResult);

        // Sauvegarder la progression
        saveProgress(updatedResult);

        setTimeout(() => {
            setAnimationClass("slideDown");
        }, 700);

        setTimeout(() => {
            setColorStar(0);
            setAnimationClass("slideToTop");
            if (valueSondage === dataSondage.length - 2) {
                setIsComplet(true);
            }
        }, 900);

        setTimeout(() => {
            setAnimationClass("slideUp");
            if (valueSondage < dataSondage.length - 1) {
                setValueSondage(valueSondage + 1);
            }
        }, 1100);

        setTimeout(() => {
            setAnimationClass("");
        }, 2100);
    }

    useEffect(() => {
        if (result.length === dataSondage.length) {
            console.log(result);
        }
    }, [result]);

    const HandleStart = () => {
        setIsStart(true);
    };

    const handleSubmit = async () => {
        try {
            const newDataSondage = await createDataSondage(result);
            console.log("Sondage créé avec succès", newDataSondage);
        } catch (error) {
            alert("Erreur lors de la création du client");
            console.error("Erreur lors de la création du client:", error);
        }
        navigate(-1);
    };

    const handleSubmitComplet = async () => {
        handleSubmit();
    };

    const handleChange = (e) => {
        const newFormData = {
            ...formData,
            [e.target.name]: e.target.value,
        };

        setFormData(newFormData);

        // Mettre à jour result en conservant les autres données
        setResult((prevResult) => {
            const newResult = { ...prevResult }; // Copier l'état précédent de result

            // Mettre à jour ou ajouter le champ spécifique
            newResult[e.target.name] = e.target.value;

            return newResult;
        });
    };

    const saveProgress = async (updatedResult) => {
        try {
            await createDataSondage(updatedResult);
            console.log("Progression sauvegardée !");
        } catch (error) {
            console.error(
                "Erreur lors de la sauvegarde de la progression :",
                error
            );
        }
    };

    return (
        <div style={{ overflow: "hidden" }}>
            <div
                id="slideSondagePage"
                className={`sondagePage page WiewState ${animationClass}`}
            >
                <div className="contentSondage">
                    {isStart ? (
                        <p>{dataSondage[valueSondage]}</p>
                    ) : (
                        <p>Notre sondage prend moins de 60 secondes !</p>
                    )}
                </div>
                <div className="ratingStar">
                    {isStart ? (
                        <>
                            {isComplet ? (
                                <div className="merci">
                                    <input
                                        type="text"
                                        name="nom"
                                        placeholder="Nom, Prénom"
                                        value={formData.nom}
                                        onChange={handleChange}
                                    />
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    <input
                                        type="text"
                                        name="entreprise"
                                        placeholder="Entreprise"
                                        value={formData.entreprise}
                                        onChange={handleChange}
                                    />
                                    {onLanguage ? (
                                        <div
                                            style={{
                                                backgroundColor: `${onData.couleur}`,
                                            }}
                                            onClick={handleSubmitComplet}
                                            className="activeButton"
                                        >
                                            {onLanguage.demandeDeService.send}
                                        </div>
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                            ) : (
                                <>
                                    {Array.from({ length: 5 }, (_, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={
                                                    colorStar === index + 1
                                                        ? "blinking"
                                                        : colorStar > index + 1
                                                        ? "selected"
                                                        : "unselected"
                                                }
                                            >
                                                {colorStar >= index + 1 ? (
                                                    <StartSelected
                                                        fill={"#FCA62F"}
                                                        onClick={() =>
                                                            HandleSelect(
                                                                index + 1
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <StartUnSelected
                                                        onClick={() =>
                                                            HandleSelect(
                                                                index + 1
                                                            )
                                                        }
                                                    />
                                                )}
                                                <p>{index + 1}</p>
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                        </>
                    ) : (
                        <div
                            style={{
                                backgroundColor: `${onData.couleur}`,
                            }}
                            onClick={HandleStart}
                            className="activeButton"
                        >
                            C'est parti !
                        </div>
                    )}
                </div>
                {isComplet ? (
                    <></>
                ) : isStart ? (
                    <div className="progressionBarContainer">
                        <div className="ProgressionBarBackground">
                            <p>
                                {valueSondage} / {dataSondage.length - 1}
                            </p>
                        </div>
                        <div
                            style={{
                                width: `${
                                    (valueSondage / (dataSondage.length - 1)) *
                                    100
                                }%`,
                            }}
                            className="ProgressionBar"
                        ></div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}
