import React, {
    useEffect,
    useRef,
    useContext,
    useState,
    useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { LanguageValue } from "../context/LanguageValue";
import LogoGo from "../assets/svg/logo/greenoffice.svg";
import BorneFacette from "../assets/image/DemoLandingPage/borneFacette.jpg";
import borneView from "../assets/image/DemoLandingPage/borneView.png";
import LogoGoLong from "../assets/svg/logo/GreenofficeDemo.svg";
import Arrow from "../assets/svg/arrow.svg";
import "../styles/DemoContact.css";
import emailjs from "@emailjs/browser";
import { getOnLanguage } from "./api";
import Loading from "./Loading";

export default function DemoContact() {
    const navigate = useNavigate();
    const form = useRef();
    const { language } = useContext(LanguageValue);
    const [onLanguage, setOnLanguage] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_99e404j",
                "template_06yvp35",
                form.current,
                "kuaqzf0uuM1ielbj9"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    navigate("/");
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    const fetchData = useCallback(async () => {
        try {
            const languageData = await getOnLanguage(language);
            setOnLanguage(languageData);
        } catch (error) {
            console.log(
                "Une erreur s'est produite lors de la récupération des données."
            );
        }
    }, [language]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    function handleScroll() {
        if (window.innerHeight > window.pageYOffset) {
            document
                .getElementById("scroll2")
                .scrollIntoView({ behavior: "smooth" });
        } else if (window.innerHeight * 2 > window.pageYOffset) {
            document
                .getElementById("scroll3")
                .scrollIntoView({ behavior: "smooth" });
        } else {
            document
                .getElementById("scroll4")
                .scrollIntoView({ behavior: "smooth" });
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleConsoleScroll);
        return () => {
            window.removeEventListener("scroll", handleConsoleScroll);
        };
    }, []);

    function handleConsoleScroll() {
        const scrollButton = document.getElementById("scrollButton");
        if (scrollButton) {
            if ((window.innerHeight + 100) * 2 > window.pageYOffset) {
                scrollButton.style.display = "flex";
            } else {
                scrollButton.style.display = "none";
            }
        }
    }

    return (
        <div className="demoContactPage">
            <div
                id="scrollButton"
                onClick={() => handleScroll()}
                className="activeButton callToAction"
            >
                <img src={Arrow} alt="" />
            </div>

            <article id="scroll1" className="firstActicle">
                <video width="100%" height="100%" autoPlay muted loop>
                    {/* <video width="100%" height="100%" > */}
                    <source
                        src={
                            process.env.PUBLIC_URL +
                            "/video/greenoffice-video.mp4"
                        }
                        type="video/mp4"
                    />
                    Votre navigateur ne supporte pas la balise vidéo.
                </video>
                <div className="calqueVideo">
                    <img src={LogoGo} alt="" />
                    {onLanguage ? (
                        <>
                            <h2>{onLanguage.demoContact.titlePart1}</h2>
                            <h2>{onLanguage.demoContact.titlePart2}</h2>
                            <p>{onLanguage.demoContact.catchPhrase}</p>
                        </>
                    ) : (
                        <Loading />
                    )}
                </div>
            </article>

            <article id="scroll2">
                <img
                    className="imgArticle"
                    src={BorneFacette}
                    alt="borne modéle facette"
                />
                <div className="partieColorBlue partieColor">
                    <img
                        className="logoMini"
                        src={LogoGo}
                        alt="logo greenoffice"
                    />
                    <div className="content">
                        {onLanguage ? (
                            <>
                                <h2>{onLanguage.demoContact.article1Title}</h2>
                                <p>{onLanguage.demoContact.article1Content}</p>
                                <p>{onLanguage.demoContact.article1Content2}</p>
                            </>
                        ) : (
                            <Loading />
                        )}
                    </div>
                </div>
            </article>

            <article id="scroll3">
                <div className="partieColorOrange partieColor">
                    <img
                        className="logoMini"
                        src={LogoGo}
                        alt="logo greenoffice"
                    />
                    <div className="content">
                        {onLanguage ? (
                            <>
                                <h2>
                                    {onLanguage.demoContact.article2TitlePart1}
                                    <br />
                                    {onLanguage.demoContact.article2TitlePart2}
                                    <br />
                                    {onLanguage.demoContact.article2TitlePart3}
                                </h2>
                                <p>
                                    {onLanguage.demoContact.article2Content}
                                    <br />
                                    {onLanguage.demoContact.article2Content2}
                                </p>
                            </>
                        ) : (
                            <Loading />
                        )}
                    </div>
                </div>
                <img
                    className="imgArticle"
                    src={borneView}
                    alt="Borne avec module view"
                />
            </article>

            <div id="scroll4" className="backgroundForm">
                <form className="form" ref={form} onSubmit={sendEmail}>
                    {onLanguage ? (
                        <>
                            <h2>{onLanguage.demoContact.formTitle}</h2>
                            <label>{onLanguage.demoContact.formPrenom}</label>
                            <input
                                placeholder={onLanguage.demoContact.formPrenom}
                                type="text"
                                name="prenom"
                            />
                            <label>{onLanguage.demoContact.formNom}</label>
                            <input
                                placeholder={onLanguage.demoContact.formNom}
                                type="text"
                                name="nom"
                            />
                            <label>{onLanguage.demoContact.formEmail}</label>
                            <input
                                placeholder="Email@email.com"
                                type="email"
                                name="email"
                            />
                            <label>
                                {onLanguage.demoContact.formTéléphone}
                            </label>
                            <input
                                placeholder="06 01 02 03 04"
                                type="tel"
                                name="tel"
                            />
                            <label>
                                {onLanguage.demoContact.formEntreprise}
                            </label>
                            <input
                                placeholder="Greenoffice"
                                type="text"
                                name="entreprise"
                            />
                            <label>{onLanguage.demoContact.formPays}</label>
                            <input
                                placeholder="France"
                                type="text"
                                name="pays"
                            />
                            <label>{onLanguage.demoContact.formPostal}</label>
                            <input
                                placeholder="81000"
                                type="number"
                                name="postal"
                            />
                            <label>{onLanguage.demoContact.formMessage}</label>
                            <textarea
                                placeholder={
                                    onLanguage.demoContact
                                        .formMessagePlaceholder
                                }
                                name="message"
                            />
                            <input
                                className="activeButton"
                                type="submit"
                                value="Envoyer"
                            />
                        </>
                    ) : (
                        <Loading />
                    )}
                </form>
            </div>

            <footer className="footerDemo">
                <img
                    onClick={() => window.open("https://www.green-office.com")}
                    src={LogoGoLong}
                    alt=""
                />
            </footer>
        </div>
    );
}
